import React, { Suspense, lazy, useState } from 'react';
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
// import Footer from "@layout/footer/layout-01";
import StickyMenu from "@containers/sticky-menu";
import FeatureGrid from "@components/feature-grid";
import PartnerArea from "@containers/partner/layout-01";
import IntroArea from "@components/introarea/layout-1";
import Slider3D from "@containers/global/slider-3d";
import PageHeader from "@containers/page-header/layout-01";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import BoxSectionOne from "@components/BoxSection/layout-three/layout-2";
import BoxSectionTwo from "@components/BoxSection/layout-one/layout-2";
import FaqArea from "@containers/faq/layout-03";
import CtaArea from "@containers/cta/layout-02";
import CaseStudy from "@containers/global/case-study";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";
import QuoteForm from "@containers/translation-quote-form";
import UseScroll from "@containers/scroll";

const Footer = lazy(() => import("@layout/footer/layout-01"))
const GameLocalizationPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 30) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="Game Localization Services | Localization Company"
        description="Premium Game Translation & Localization Services by Andovar Localization Company. Globalize your audience by localizing your games."
        />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <StickyMenu data={content["stycky-menu-data"]} />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["game-localization-page-header-data"]} />
        <QuoteForm />
        {
          isMobile && showRestComponents ? <>
             <PartnerArea data={content["game-localization-logo"]} />
        <FeatureGrid data={content["game-localization-features"]} />
          </> : !isMobile && <>
          <PartnerArea data={content["game-localization-logo"]} />
        <FeatureGrid data={content["game-localization-features"]} />
          </>
        }
        <IntroArea layout={2} data={content["game-intro"]} />
        <BoxSectionOne
          layout={1}
          data={content["game-platform-localization"]}
        />
        {
          showRestComponents && <>
      
        <BoxSectionTwo
          layout={7}
          data={content["game-services-localization"]}
        />
        <Slider3D data={content["game-localization-applications"]} />
        <CtaArea data={content["cta-data"]} />
        <IntroAreaReverse data={content["game-testing-body"]} />
        <CaseStudy data={caseStudiesData} />
        <FaqArea data={content["game-faq-body"]} />
        <ContactArea Layout={2} />
        </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

export const query = graphql`
  query gameLocalizationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "game-localization" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

GameLocalizationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default GameLocalizationPage;
